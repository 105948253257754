<template>
  <div v-b-tooltip.hover.right="'Logout'">
    <button
      @click="btnLogout"
      class="btn btn-icon btn-clean btn-lg mb-1 btn-logout"
      data-original-title="Quick Actions"
    >
      <span class="svg-icon svg-icon-xl">
        <!--begin::Svg Icon-->
        <!-- <inline-svg src="media/svg/icons/Media/Equalizer.svg" /> -->
        <v-icon color="white">mdi-logout-variant</v-icon>
        <!--end::Svg Icon-->
      </span>
    </button>
  </div>
</template>

<script>
import KTLayoutQuickActions from "@/assets/js/layout/extended/quick-actions.js";
import Auth from "@/core/services/auth";
export default {
  name: "KTQuickLogout",
  data() {
    return {};
  },
  mounted() {
    // Init Quick Actions Offcanvas Panel
    KTLayoutQuickActions.init(this.$refs["kt_quick_actions"]);
  },
  methods: {
    btnLogout() {
      Auth.logout();
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="css">
.btn-logout {
  background-color: #f5f5f9 !important;
}
.btn-logout i {
  color: #369ffe !important;
}
</style>
