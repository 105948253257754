<template>
  <!-- begin:: Aside -->
  <div class="aside aside-left d-flex aside-fixed" id="kt_aside" ref="kt_aside">
    <!--begin::Primary-->
    <div
        class="aside-primary d-flex flex-column align-items-center flex-row-auto"
    >
      <!--begin::Brand-->
      <KTBrand></KTBrand>
      <!--end::Brand-->
      <!--begin::Nav Wrapper-->
      <div
          class="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull ps"
          style="height: 528px; overflow: hidden"
      >
        <!--begin::Nav-->
        <ul class="nav flex-column" role="tablist">
          <!--begin::Item-->
          <li
              id="menu-student"
              class="nav-item mb-3 hover-menu-select"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-hover="handleHoverStudentManagement"
              v-if="canAccess('student-view')"
          >
            <span class="svg-icon svg-icon-xl ml-2 mr-4">
              <v-icon class="icon-menu">mdi-account-edit</v-icon>
            </span>
            <v-card
                class="mx-auto custom-menu-item mb-2"
                :class="{ 'hide-menu-item': !hoverItemStudentManagement }"
            >
              <h6 class="p-2">Student Management</h6>
              <router-link
                  :to="{ name: 'StudentList' }"
                  style="color: #212121"
                  v-if="canAccess('student-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-school</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Student Management</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'HighScore' }"
                  style="color: #212121"
                  v-if="canAccess('student-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-account-star-outline</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Học sinh điểm cao</h6>
                </div>
              </router-link>
            </v-card>
          </li>
          <li
              id="menu-marking"
              class="nav-item mb-3 hover-menu-select"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-hover="handleHoverMarking"
              v-if="
              canAccess('assign-marker-for-course') ||
              canAccess('view-ticket') ||
              canAccess('assign-marker-for-class')
            "
          >
            <span class="svg-icon svg-icon-xl ml-2 mr-4">
              <v-icon class="icon-menu">mdi-pencil-plus</v-icon>
            </span>
            <v-card
                class="mx-auto custom-menu-item mb-2"
                :class="{ 'hide-menu-item': !hoverItemMarking }"
            >
              <h6 class="p-2">Marking</h6>
              <router-link
                  :to="{ name: 'TabMarker' }"
                  style="color: #212121"
                  v-if="
                  canAccess('assign-marker-for-course') ||
                  canAccess('assign-marker-for-class')
                "
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-account-edit</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Assign Markers</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'MarkingTicket' }"
                  style="color: #212121"
                  v-if="canAccess('view-ticket')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-ticket-account</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Marking Tickets</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'ListMarkerConfig' }"
                  style="color: #212121"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-account-settings</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Marker Config</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'MarkerHistory' }"
                  style="color: #212121"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-history</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Lịch sử gắn ticket</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'StatisticalDeadlineTicket' }"
                  style="color: #212121"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-air-filter</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Thống kế chấm bài, nộp bài</h6>
                </div>
              </router-link>
            </v-card>
          </li>
          <li
              id="menu-learning"
              class="nav-item mb-3 hover-menu-select"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-hover="handleHoverLearningContend"
              v-if="
              canAccess('test-view') ||
              canAccess('course-view') ||
              canAccess('programme-view') ||
              canAccess('grading-scale-view') ||
              canAccess('entry-test-view') ||
              canAccess('element-view')
            "
          >
            <span class="svg-icon svg-icon-xl ml-2 mr-4">
              <v-icon>mdi-school</v-icon>
            </span>
            <v-card
                class="mx-auto custom-menu-item mb-2"
                :class="{ 'hide-menu-item': !hoverItemLearningContend }"
            >
              <h6 class="p-2">Learning contents</h6>
              <router-link
                  :to="{ name: 'TabTestList' }"
                  style="color: #212121"
                  v-if="canAccess('test-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-pencil-plus</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Lesson & Test List</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'CouresList' }"
                  style="color: #212121"
                  v-if="canAccess('course-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-book-open-outline</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Course Management</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'ProgrammeList' }"
                  style="color: #212121"
                  v-if="canAccess('programme-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-book-multiple</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Programme</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'GradingScaleList' }"
                  style="color: #212121"
                  v-if="canAccess('grading-scale-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-scale-balance</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Grading Scale Management</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'EntryTest' }"
                  style="color: #212121"
                  v-if="canAccess('entry-test-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-file-document-edit-outline</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Entry Test</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'ResultAnalysis' }"
                  style="color: #212121"
                  v-if="canAccess('entry-test-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-feature-search-outline</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Entry Test Result Analysis</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'VideoList' }"
                  style="color: #212121"
                  v-if="canAccess('element-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-video-check-outline</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Video Management</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'CategoryMaterialList' }"
                  style="color: #212121"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-briefcase-download</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Quản lí tải tài liệu</h6>
                </div>
              </router-link>
              <router-link :to="{ name: 'TestSetList' }" style="color: #212121">
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-file-document-edit-outline</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Quản lí Test set</h6>
                </div>
              </router-link>
            </v-card>
          </li>
          <li
              id="menu-business"
              class="nav-item mb-3 hover-menu-select"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-hover="handleHoverBusinessManagement"
              v-if="
              canAccess('order-view') ||
              canAccess('discount-view') ||
              canAccess('partner-view') ||
              canAccess('coupon-view') ||
              canAccess('payment-guide-view') ||
              canAccess('group-coupon-view')
            "
          >
            <span class="svg-icon svg-icon-xl ml-2 mr-4">
              <v-icon class="icon-menu">mdi-cash-usd-outline</v-icon>
            </span>
            <v-card
                class="mx-auto custom-menu-item mb-2"
                :class="{ 'hide-menu-item': !hoverItemBusinessManagement }"
            >
              <h6 class="p-2">Business Management:</h6>
              <router-link
                  :to="{ name: 'TabOrderStatistical' }"
                  style="color: #212121"
                  v-if="canAccess('order-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-cart</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Order Management & Statistical</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'TabListDiscount' }"
                  style="color: #212121"
                  v-if="canAccess('discount-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-sale</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Discount Management</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'ListPartner' }"
                  style="color: #212121"
                  v-if="canAccess('partner-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-handshake</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Partner Management</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'CouponList' }"
                  style="color: #212121"
                  v-if="canAccess('coupon-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-ticket-percent-outline</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Coupon Management</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'CouponGroupList' }"
                  style="color: #212121"
                  v-if="canAccess('group-coupon-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-ticket-percent</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Coupon Group Management</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'PaymentGuide' }"
                  style="color: #212121"
                  v-if="canAccess('payment-guide-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-credit-card</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Payment Method Guide</h6>
                </div>
              </router-link>
            </v-card>
          </li>
          <li
              id="menu-global"
              class="nav-item mb-3 hover-menu-select"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-hover="handleHoverGlobal"
              v-if="
              canAccess('permission-view') ||
              canAccess('role-view') ||
              canAccess('user-view') ||
              canAccess('faq-view')
            "
          >
            <span class="svg-icon svg-icon-xl ml-2 mr-4">
              <v-icon class="icon-menu">mdi-shield-account</v-icon>
            </span>
            <v-card
                class="mx-auto custom-menu-item mb-2"
                :class="{ 'hide-menu-item': !hoverItemGlobal }"
            >
              <h6 class="p-2">Global:</h6>
              <router-link
                  :to="{ name: 'PermissionList' }"
                  style="color: #212121"
                  v-if="canAccess('permission-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-account-key</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Permission Management</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'RoleList' }"
                  style="color: #212121"
                  v-if="canAccess('role-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-sitemap</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Role Management</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'UserList' }"
                  style="color: #212121"
                  v-if="canAccess('user-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-account-group</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Staff Management</h6>
                </div>
              </router-link>
            </v-card>
          </li>
          <li
              id="menu-tag"
              class="nav-item mb-3 hover-menu-select"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-hover="handleHoverTag"
              v-if="canAccess('tag-view')"
          >
            <span class="svg-icon svg-icon-xl ml-2 mr-4">
              <v-icon class="icon-menu">mdi-tag-multiple</v-icon>
            </span>
            <v-card
                class="mx-auto custom-menu-item mb-2"
                :class="{ 'hide-menu-item': !hoverItemTag }"
            >
              <h6 class="p-2">Tag:</h6>
              <router-link
                  :to="{ name: 'TagTypeList' }"
                  style="color: #212121"
                  v-if="canAccess('tag-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-tag-faces</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Tag Type</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'TagList' }"
                  style="color: #212121"
                  v-if="canAccess('tag-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-tag-multiple</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Tag Management</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'ConversationTagList' }"
                  style="color: #212121"
                  v-if="canAccess('cs-view')"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-tag-text-outline</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Conversation Tag</h6>
                </div>
              </router-link>
            </v-card>
          </li>
          <li
              id="menu-other"
              class="nav-item mb-3 hover-menu-select"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-hover="handleHoverTestimony"
          >
            <span class="svg-icon svg-icon-xl ml-2 mr-4">
              <v-icon class="icon-menu"
              >mdi-view-dashboard-variant-outline</v-icon
              >
            </span>
            <v-card
                class="mx-auto custom-menu-item mb-2"
                :class="{ 'hide-menu-item': !hoverItemTestimony }"
            >
              <h6 class="p-2">Other:</h6>
              <router-link
                  :to="{ name: 'TestimonyList' }"
                  style="color: #212121"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-message-text</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Testimony Management</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'ListInformation' }"
                  style="color: #212121"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-information-variant</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Prep Information</h6>
                </div>
              </router-link>
              <router-link :to="{ name: 'SurveyList' }" style="color: #212121">
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-notebook-check</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Survey Mangement</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'InstantFeedbackList' }"
                  style="color: #212121"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-comment-quote-outline</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Instant Feedback</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'TopMessageList' }"
                  style="color: #212121"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-bell-ring</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Top Message</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'TopBannerList' }"
                  style="color: #212121"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-picture-in-picture-top-right</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Top Banner</h6>
                </div>
              </router-link>
              <router-link :to="{ name: 'NewsList' }" style="color: #212121">
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-newspaper-variant</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">News</h6>
                </div>
              </router-link>
              <router-link :to="{ name: 'PopupList' }" style="color: #212121">
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-flower-poppy</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Popup</h6>
                </div>
              </router-link>
            </v-card>
          </li>
          <li
              id="menu-product"
              class="nav-item mb-3 hover-menu-select"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-hover="handleHoverProduct"
          >
            <span class="svg-icon svg-icon-xl ml-2 mr-4">
              <!--              <v-icon class="icon-menu">mdi-message-text</v-icon>-->
              <i
                  class="fab fa-product-hunt icon-menu fontawesome-icon-menu"
              ></i>
            </span>
            <v-card
                class="mx-auto custom-menu-item mb-2"
                :class="{ 'hide-menu-item': !hoverItemProduct }"
            >
              <h6 class="p-2">Product:</h6>
              <router-link
                  :to="{ name: 'ProductCategoryList' }"
                  style="color: #212121"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <i class="fab fa-product-hunt fontawesome-icon-menu ml-4"></i>
                  <h6 class="mt-2 ml-1 mr-1">Product Category</h6>
                </div>
              </router-link>
              <router-link :to="{ name: 'SEOList' }" style="color: #212121">
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-alpha-s-circle</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">SEO Information</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'ServicePackageList' }"
                  style="color: #212121"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-package-variant</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Quản lí gói dịch vụ</h6>
                </div>
              </router-link>
              <router-link :to="{ name: 'RoadmapList' }" style="color: #212121">
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-chart-timeline</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Quản lí lộ trình sản phẩm</h6>
                </div>
              </router-link>
              <router-link
                  :to="{ name: 'ActivationCodeList' }"
                  style="color: #212121"
              >
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <v-icon class="ml-4">mdi-star-four-points-outline</v-icon>
                  <h6 class="mt-2 ml-1 mr-1">Quản lí Activation Code</h6>
                </div>
              </router-link>
            </v-card>
          </li>

          <!--          <li
                        id="menu-web"
                        class="nav-item mb-3 hover-menu-select"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                        v-b-hover="handleHoverShowWeb"
                    >
                      <span class="svg-icon svg-icon-xl ml-2 mr-4">
                        <v-icon>mdi-view-comfy</v-icon>
                      </span>
                      <v-card
                          class="mx-auto custom-menu-item mb-2"
                          :class="{ 'hide-menu-item': !hoverItemShowWeb }"
                      >
                        <h6 class="p-2">Quản lí mục hiển thị web:</h6>
                        <router-link
                            :to="{ name: 'TeacherShowWeb' }"
                            style="color: #212121"
                            v-if="canAccess('teacher-view')"
                        >
                          <div class="d-flex hover-item p-1" style="cursor: pointer">
                            <v-icon class="ml-4">mdi-account-multiple</v-icon>
                            <h6 class="mt-2 ml-1 mr-1">Show teacher in web</h6>
                          </div>
                        </router-link>
                        <router-link :to="{ name: 'PromiseList' }" style="color: #212121">
                          <div class="d-flex hover-item p-1" style="cursor: pointer">
                            <v-icon class="ml-4">mdi-star-settings</v-icon>
                            <h6 class="mt-2 ml-1 mr-1">Cam kết</h6>
                          </div>
                        </router-link>
                        <router-link
                            :to="{ name: 'FaqCategory' }"
                            style="color: #212121"
                            v-if="canAccess('faq-category-view')"
                        >
                          <div class="d-flex hover-item p-1" style="cursor: pointer">
                            <v-icon class="ml-4">mdi-frequently-asked-questions</v-icon>
                            <h6 class="mt-2 ml-1 mr-1">FAQ</h6>
                          </div>
                        </router-link>
                      </v-card>
                    </li>
                    <li
                        id="menu-chat"
                        class="nav-item mb-3 hover-menu-select"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                        v-b-hover="handleHoverShowChat"
                    >
                      <span class="svg-icon svg-icon-xl ml-2 mr-4 link-chat">
                        <i class="fas fa-comments icon-menu fontawesome-icon-menu"></i>
                        <span class="tick" v-if="totalNewNotify() > 0"></span>
                      </span>
                      <v-card
                          class="mx-auto custom-menu-item mb-2"
                          :class="{ 'hide-menu-item': !hoverItemShowChat }"
                      >
                        <h6 class="p-2">Tin nhắn:</h6>
                        <router-link :to="{ name: 'Chat' }" style="color: #212121">
                          <div class="d-flex hover-item p-1" style="cursor: pointer">
                            <v-icon class="ml-4">mdi-star-settings</v-icon>
                            <h6 class="mt-2 ml-1 mr-1">Danh sách hội thoại</h6>
                          </div>
                        </router-link>
                        <router-link :to="{ name: 'ChatCS' }" style="color: #212121">
                          <div class="d-flex hover-item p-1" style="cursor: pointer">
                            <v-icon class="ml-4">mdi-star-settings</v-icon>
                            <h6 class="mt-2 ml-1 mr-1">Danh sách hỗ trợ</h6>
                          </div>
                        </router-link>
                        <router-link
                            :to="{ name: 'ChatManager' }"
                            style="color: #212121"
                            v-if="canAccess('chat-management')"
                        >
                          <div class="d-flex hover-item p-1" style="cursor: pointer">
                            <v-icon class="ml-4">mdi-alpha-s-circle</v-icon>
                            <h6 class="mt-2 ml-1 mr-1">Quản lý tin nhắn</h6>
                          </div>
                        </router-link>
                        <router-link
                            :to="{ name: 'ChatManagerCS' }"
                            style="color: #212121"
                            v-if="canAccess('chat-cs-management')"
                        >
                          <div class="d-flex hover-item p-1" style="cursor: pointer">
                            <v-icon class="ml-4">mdi-alpha-s-circle</v-icon>
                            <h6 class="mt-2 ml-1 mr-1">Quản lý tin nhắn với CS</h6>
                          </div>
                        </router-link>
                      </v-card>

                    </li>-->

          <li
              id="menu-chat"
              class="nav-item mb-3 hover-menu-select"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-hover="handleHoverShowChat"
          >
            <span class="svg-icon svg-icon-xl ml-2 mr-4 link-chat">
              <i class="fas fa-comments icon-menu fontawesome-icon-menu"></i>
              <span class="tick" v-if="totalNewNotify() > 0"></span>
            </span>
            <v-card
                class="mx-auto custom-menu-item mb-2"
                :class="{ 'hide-menu-item': !hoverItemShowChat }"
            >
              <h6 class="p-2">Quản lý trao đổi với học viên: </h6>
              <router-link :to="{ name: 'chatWithStudent',query:{status:'1,3'} }" style="color: #212121">
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <h6 class="mt-2 ml-1 mr-1">Cần phản hồi</h6>
                </div>
              </router-link>
              <router-link :to="{  name: 'chatWithStudent',query:{status:''} }" style="color: #212121">
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <h6 class="mt-2 ml-1 mr-1">Tất cả</h6>
                </div>
              </router-link>
              <router-link :to="{  name: 'chatWithStudent',query:{status:'2,4'} }" style="color: #212121">
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <h6 class="mt-2 ml-1 mr-1">Đã phản hồi</h6>
                </div>
              </router-link>
              <router-link :to="{  name: 'chatWithStudent',query:{status:'5'}  }" style="color: #212121">
                <div class="d-flex hover-item p-1" style="cursor: pointer">
                  <h6 class="mt-2 ml-1 mr-1">Đã đóng</h6>
                </div>
              </router-link>

            </v-card>

          </li>

          <!-- <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Dashboard'"
          >
            <router-link
              :to="{ name: 'dashboard'}"
              class="nav-link btn btn-icon btn-clean btn-lg"
              data-toggle="tab"
              v-on:click="setActiveTab"
              data-tab="0"
            >
              <span class="svg-icon svg-icon-xl">
                <v-icon>mdi-view-dashboard</v-icon>
              </span>
            </router-link>
          </li> -->

          <!--end::Item-->
        </ul>
        <!--end::Nav-->
      </div>
      <!--end::Nav Wrapper-->
      <!--begin::Footer-->
      <div
          class="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10"
      >
        <!--begin::Aside Toggle-->

        <!--end::Aside Toggle-->

        <!--begin::Quick Actions-->
        <!-- <KTQuickActions></KTQuickActions> -->
        <!--end::Quick Actions-->

        <!--begin::Quick Panel-->
        <!-- <KTQuickPanel></KTQuickPanel> -->
        <!--end::Quick Panel-->

        <!--begin::User-->
        <!-- <KTQuickUser></KTQuickUser> -->
        <!--end::User-->
        <KTQuickLogout></KTQuickLogout>
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Primary-->
    <!--begin::Secondary-->
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}

.aside-secondary-enabled .aside {
  width: 100px;
}
</style>

<script>
import {mapGetters} from "vuex";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
import KTBrand from "@/view/layout/brand/Brand.vue";
import KTQuickLogout from "@/view/layout/extras/offcanvas/QuickLogout.vue";
import Ls from "@/core/services/jwt.service.js";
import user from "@/plugins/prepChat/mixins/user";
import message from "@/plugins/prepChat/mixins/message";
import group from "@/plugins/prepChat/mixins/group";

export default {
  name: "KTAside",
  mixins: [group, user, message],
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
      tabIndex: 0,
      hoverItemStudentManagement: false,
      hoverItemMarking: false,
      hoverItemLearningContend: false,
      hoverItemBusinessManagement: false,
      hoverItemGlobal: false,
      hoverItemTag: false,
      hoverItemTestimony: false,
      hoverItemProduct: false,
      hoverItemShowWeb: false,
      hoverItemShowChat: false,
      isLogin: false,
      user: null,
    };
  },
  components: {
    KTBrand,
    //KTMenu: () => import('@/view/layout/aside/Menu.vue'),
    // KTQuickActions: () => import('@/view/layout/extras/offcanvas/QuickActions.vue'),
    // KTQuickUser: () => import('@/view/layout/extras/offcanvas/QuickUser.vue'),
    // KTQuickPanel: () => import('@/view/layout/extras/offcanvas/QuickPanel.vue')
    KTQuickLogout,
  },
  mounted() {
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);

      // Init Aside Toggle
      KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
    });
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
    handleHoverStudentManagement(hovered) {
      this.showMenuItem("menu-student");
      this.hoverItemStudentManagement = hovered;
    },
    handleHoverMarking(hovered) {
      this.showMenuItem("menu-marking");
      this.hoverItemMarking = hovered;
    },
    handleHoverLearningContend(hovered) {
      this.showMenuItem("menu-learning");
      this.hoverItemLearningContend = hovered;
    },
    handleHoverBusinessManagement(hovered) {
      this.showMenuItem("menu-business");
      this.hoverItemBusinessManagement = hovered;
    },
    handleHoverGlobal(hovered) {
      this.showMenuItem("menu-global");
      this.hoverItemGlobal = hovered;
    },
    handleHoverTag(hovered) {
      this.showMenuItem("menu-tag");
      this.hoverItemTag = hovered;
    },
    handleHoverTestimony(hovered) {
      this.showMenuItem("menu-other");
      this.hoverItemTestimony = hovered;
    },
    handleHoverProduct(hovered) {
      this.showMenuItem("menu-product");
      this.hoverItemProduct = hovered;
    },
    handleHoverShowWeb(hovered) {
      this.showMenuItem("menu-web");
      this.hoverItemShowWeb = hovered;
    },
    handleHoverShowChat(hovered) {
      this.showMenuItem("menu-chat");
      this.hoverItemShowChat = hovered;
    },
    totalNewNotify() {
      let count = 0;
      let user = JSON.parse(Ls.getUser());
      let groups = this.$store.state.prepChatGroup.groups ?? [];
      groups.forEach((element) => {
        if (element.recentMessage) {
          if (!element.recentMessage.readBy.includes(user.id)) {
            count++;
            console.log(element.recentMessage);
          }
        }
      });

      let groupsCs = this.$store.state.prepChatGroup.groupsCs ?? [];
      groupsCs.forEach((element) => {
        if (element.recentMessage) {
          if (!element.recentMessage.readBy.includes(user.id)) {
            count++;
          }
        }
      });

      let groupsManagerCs =
          this.$store.state.prepChatGroup.groupsManagerCs ?? [];
      groupsManagerCs.forEach((element) => {
        if (element.members) {
          if (Object.keys(element.members).length == 1) {
            count++;
          }
        }
      });

      return count;
    },
    showMenuItem(element_id) {
      let element = document.getElementById(element_id);
      let yPosition = element.getBoundingClientRect().top;
      element.childNodes[1].style.top = yPosition + "px";
    },
    login() {
      let userData = null;
      //pmprep
      if (localStorage.getItem("data_user")) {
        let localUser = JSON.parse(localStorage.getItem("data_user"));
        userData = {
          uid: Number(localUser.id),
          email: localUser.email,
          displayName: localUser.name,
          photoURL: localUser.avatar,
          type: "prep_user",
          phone: localUser.phone,
        };
      }

      if (userData) {
        this.user = userData;
        this.isLogin = true;
      }
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
  },
  watch: {
    async user(val) {
      if (!val) return;
      this.$store.commit("prepChatUser/add", val);
      console.log("run chat");
      // await this.fetchUsers();
      const exist = await this.checkUserExisted(val);
      if (exist) {
        //update info
        await this.fetchGroupByUserID(val.uid);
        return;
      }
      this.saveUser(val);
    },
  },
  created() {
    this.login();
  },
};
</script>
<style lang="css">
.btn.btn-clean:not(:disabled):not(.disabled).active i {
  color: #002e4d !important;
}

.btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled) {
  color: #002e4d !important;
}

.custom-menu-item {
  position: fixed !important;
  display: inline !important;
  z-index: 1000 !important;
}

.hide-menu-item {
  display: none !important;
}

.hover-menu-select {
  line-height: 3;
}

.hover-menu-select:hover {
  background-color: #f5f5f9;
}

.hover-menu-select:hover .icon-menu {
  color: #369ffe;
}

.hover-item:hover {
  background-color: #f5f5f9;
}

.hover-item:hover i {
  color: #369ffe;
}

.hover-item:hover h6 {
  color: #369ffe;
}

.fontawesome-icon-menu {
  font-size: 24px;
  color: #757575;
}

.link-chat {
  position: relative;
}

.link-chat .tick {
  position: absolute;
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 50%;
}
</style>
